import styled from "styled-components";

const StyledTitle = styled.h2`
  display: inline-block;
  text-transform: uppercase;
  position: relative;
  color: ${props =>
    props.color === "white" ? "#fff" : props.theme.colors.text.lightGrey};
  font-weight: 600;
  padding-top: 16px;

  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    height: 6px;
    width: 100%;
    background-color: ${props =>
      props.color === "white"
        ? "rgba(255, 255, 255, 0.5)"
        : "rgba(40, 184, 184, 0.4)"};
  }
`;

export default StyledTitle;
