import styled from "styled-components"

const Text = styled.p`
  text-align: ${props => (props.leftMobile ? "left" : "center")};
  margin-bottom: 16px;
  line-height: 32px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    text-align: ${props =>
      props.left || props.leftMobile ? "left" : "center"};
  }
`

export default Text
