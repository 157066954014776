import styled, { css } from "styled-components";

const active = css`
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 6px;
    width: 100%;
    background-color: rgba(40, 184, 184, 0.4);
  }
`;

const NavLink = styled.li`
  margin-left: 32px;
  position: relative;

  ${props => props.active && active}
`;

export default NavLink;
