import React, { useContext } from "react";
import { MenuContext } from "../../../../context/MenuContext";
//Styled components imports
import Wrapper from "./Wrapper";
import Logo from "./Logo";
import Nav from "./Nav";
import NavLinks from "./NavLinks";
import NavLink from "./NavLink";
import Link from "./Link";

const MenuOverlay = () => {
  const [menuOpen, setMenuOpen] = useContext(MenuContext);
  const overlayVariants = {
    initial: {
      y: "-100%",
      transition: {
        type: "tween",
        duration: 0.3,
      },
    },
    open: {
      y: 0,
      transition: {
        type: "tween",
        duration: 0.3,
      },
    },
  };

  return (
    <Wrapper
      key="menu"
      variants={overlayVariants}
      initial="initial"
      animate="open"
      exit="initial"
    >
      <Logo />
      <Nav>
        <NavLinks>
          <NavLink>
            <Link
              to="/"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              aria-label="Domů"
            >
              Domů
            </Link>
          </NavLink>
          <NavLink>
            <Link
              to="/#about"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              aria-label="O firmě"
            >
              O firmě
            </Link>
          </NavLink>
          <NavLink>
            <Link
              to="/#products"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              aria-label="Produkty"
            >
              Produkty
            </Link>
          </NavLink>
          <NavLink>
            <Link
              to="/#contact"
              onClick={() => {
                setMenuOpen(!menuOpen);
              }}
              aria-label="Kontakt"
            >
              Kontakt
            </Link>
          </NavLink>
        </NavLinks>
      </Nav>
    </Wrapper>
  );
};

export default MenuOverlay;
