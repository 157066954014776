import { breakpoints } from "../Media";

export const theme = {
  colors: {
    main: "#28B8B8",
    text: {
      grey: "#3F3F3F",
      lightGrey: "#7D7E82",
    },
  },
  mediaQueries: {
    mobile: `min-width: ${breakpoints.mobile}px`,
    tablet: `min-width: ${breakpoints.tablet}px`,
    desktop: `min-width: ${breakpoints.desktop}px`,
    largeDesktop: `min-width: ${breakpoints.largeDesktop}px`,
  },
};
