import React from "react";
import { useLocation } from "@reach/router";

//React components imports
import Contact from "./Contact";

//Styled components imports
import Wrapper from "./Wrapper";
import Container from "./Container";
import Copyright from "./Copyright";
import Logo from "./Logo";

const Footer = () => {
  const location = useLocation();

  return (
    <Wrapper>
      {location.pathname === "/" ? <Contact /> : null}

      {location.pathname === "/" ? (
        <Container home>
          <Logo />
          <Copyright>
            © 2009-{new Date().getFullYear()} ArteMan s.r.o.
          </Copyright>
        </Container>
      ) : (
        <Container>
          <Logo />
          <Copyright>
            © 2009-{new Date().getFullYear()} ArteMan s.r.o.
          </Copyright>
        </Container>
      )}
    </Wrapper>
  );
};

export default Footer;
