import styled from "styled-components";

const IntroText = styled.h1`
  padding: 0 5px;
  font-size: 28px;
  font-weight: 600;
  max-width: 485px;
  text-align: center;
  color: ${props => props.theme.colors.main};
  margin: 0 auto;
  text-transform: uppercase;
  margin-top: 34px;

  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 42px;
    padding: 0;
  }
`;

export default IntroText;
