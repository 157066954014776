import styled from "styled-components";

const Container = styled.div`
  margin-top: 48px;
  display: grid;
  grid-template-columns: 1fr;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 16px;
  }
`;

export default Container;
