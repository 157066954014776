import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

const Link = styled(GatsbyLink)`
  color: #fff;
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 48px;
  display: block;
  text-transform: uppercase;
`;

export default Link;
