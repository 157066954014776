import styled from "styled-components";
import ArtemanLogo from "../../../images/logo.inline.svg";

const Logo = styled(ArtemanLogo)`
  cursor: pointer;
  max-width: 200px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    max-width: 100%;
  }
`;

export default Logo;
