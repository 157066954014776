import React from "react";

//React components imports
import Title from "../../Title";

//Styled components imports
import Wrapper from "./Wrapper";
import Container from "./Container";
import Subtitle from "./Subtitle";
import Text from "./Text";
import Section from "./Section";
import BoldText from "../../../shared-styled-components/BoldText";
import Link from "./Link";

const Contact = () => {
  return (
    <Wrapper id="contact">
      <Title color="white">Kontakt</Title>
      <Container>
        <Section>
          <Subtitle>Fakturační údaje</Subtitle>
          <Text>
            <BoldText>ArteMan s.r.o.</BoldText>
            <br></br> Kališnická 1566/27<br></br> České Budějovice
            <br></br> 370 08
          </Text>
          <Text>
            IČO: 28101375<br></br>
            DIČ: CZ28101375
          </Text>
        </Section>
        <Section>
          <Subtitle>Jednatel společnosti ArteMan</Subtitle>
          <Text>
            <BoldText>Luděk Srb</BoldText> <br></br>{" "}
            <Link href="tel:+420602148862">+420 602 148 862</Link>
            <br></br>{" "}
            <Link href="mailto:srb.ludek@seznam.cz">srb.ludek@seznam.cz</Link>
          </Text>
        </Section>
        <Section justifyEnd>
          <Subtitle>Jednatel webových portálů</Subtitle>
          <Text>
            <BoldText>Luboš Srb</BoldText>
            <br></br>
            <Link href="mailto:lubos.srb@mobilizujeme.cz">
              lubos.srb@mobilizujeme.cz
            </Link>
            <br></br>
            <Link href="mailto:lubos.srb@elektrickevozy.cz">
              lubos.srb@elektrickevozy.cz
            </Link>
            <br></br>
          </Text>
        </Section>
      </Container>
    </Wrapper>
  );
};

export default Contact;
