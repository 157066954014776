import styled from "styled-components";
import ArtemanLogo from "../../images/logo-menu.inline.svg";

const Logo = styled(ArtemanLogo)`
  max-width: 200px;
  margin: 60px auto 40px;
  display: block;
`;

export default Logo;
