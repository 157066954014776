import styled from "styled-components";
import footerBg from "../../images/footer-bg.svg";
import footerBgMobile from "../../images/footer-bg-mobile.svg";

const Footer = styled.footer`
  margin-top: auto;
  background: url(${footerBgMobile}) center top no-repeat;
  background-size: cover;
  margin-top: 24px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    background: url(${footerBg}) center top no-repeat;
    background-size: cover;
  }
`;

export default Footer;
