import styled from "styled-components";

const Text = styled.p`
  color: #fff;
  margin-bottom: 16px;
  line-height: 24px;
  text-align: center;

  @media (${props => props.theme.mediaQueries.tablet}) {
    text-align: left;
  }
`;

export default Text;
