import React, { useEffect, useContext } from "react";
import { Media } from "../../../utils/Media";
import { MenuContext } from "../../../context/MenuContext";
import { AnimatePresence } from "framer-motion";
import { Link } from "gatsby";

//React components imports
import MenuIcon from "./MenuIcon";
import Nav from "./Nav";
import MenuOverlay from "./MenuOverlay";

//Styled components imports
import Wrapper from "./Wrapper";
import Logo from "./Logo";

const Navbar = () => {
  const [menuOpen] = useContext(MenuContext);

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [menuOpen]);

  return (
    <Wrapper>
      <Link to="/">
        <Logo />
      </Link>
      <Media lessThan="desktop">
        <AnimatePresence>{menuOpen ? <MenuOverlay /> : null}</AnimatePresence>
        <MenuIcon />
      </Media>
      <Media greaterThanOrEqual="desktop">
        <Nav />
      </Media>
    </Wrapper>
  );
};

export default Navbar;
