import React, { useContext } from "react";
import { motion } from "framer-motion";
import { MenuContext } from "../../../../context/MenuContext";

//Styled components imports
import Wrapper from "./Wrapper";

const MenuIcon = () => {
  const [menuOpen, setMenuOpen] = useContext(MenuContext);
  const lineOneVariants = {
    hamburger: {
      rotate: 0,
      y: 0,
    },
    cross: { rotate: 45, y: 12 },
  };

  const lineTwoVariants = {
    hamburger: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
    cross: {
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
  };

  const lineThreeVariants = {
    hamburger: {
      rotate: 0,
      y: 0,
    },
    cross: {
      rotate: -45,
      y: -12,
    },
  };
  return (
    <Wrapper
      onClick={() => {
        setMenuOpen(!menuOpen);
      }}
    >
      <svg
        width="34"
        height="36"
        viewBox="0 0 34 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          opacity="0.4"
          d="M1.19648 16.9498H32.7038C33.0029 16.9498 33.3021 16.8501 33.6012 16.551C33.8006 16.3516 34 16.0524 34 15.6536V13.2607C34 12.9615 33.9003 12.6624 33.6012 12.3633C33.4018 12.1639 33.1026 11.9645 32.7038 11.9645H1.19648C0.897361 11.9645 0.598241 12.0642 0.29912 12.3633C0.0997068 12.6624 0 12.9615 0 13.2607V15.6536C0 15.9527 0.0997067 16.2519 0.398827 16.551C0.598241 16.7504 0.897361 16.9498 1.19648 16.9498Z"
          fill={menuOpen ? "#fff" : "rgba(40, 184, 184, 0.4)"}
          variants={lineTwoVariants}
          initial="hamburger"
          animate={menuOpen ? "cross" : "hamburger"}
        />
        <motion.path
          d="M32.7038 24.1282H1.19648C0.897361 24.1282 0.598241 24.2279 0.29912 24.527C0.0997068 24.7264 0 25.0255 0 25.4244V27.8173C0 28.1164 0.0997067 28.4156 0.398827 28.7147C0.598241 28.9141 0.897361 29.0138 1.19648 29.0138H32.7038C33.0029 29.0138 33.3021 28.9141 33.6012 28.615C33.8006 28.4156 34 28.1164 34 27.7176V25.3247C34 25.0255 33.9003 24.7264 33.6012 24.4273C33.3021 24.3276 33.0029 24.1282 32.7038 24.1282Z"
          fill={menuOpen ? "#fff" : "#7D7E82"}
          variants={lineThreeVariants}
          initial="hamburger"
          animate={menuOpen ? "cross" : "hamburger"}
        />
        <motion.path
          d="M32.7038 0H1.19648C0.897361 0 0.598241 0.0997062 0.398827 0.299119C0.0997067 0.498532 0 0.797652 0 1.19648V3.58943C0 3.88855 0.0997067 4.18767 0.398827 4.48679C0.598241 4.68621 0.897361 4.88562 1.29619 4.88562H32.8035C33.1026 4.88562 33.4018 4.78591 33.7009 4.48679C33.8006 4.18767 33.9003 3.88855 33.9003 3.58943V1.19648C33.9003 0.897358 33.8006 0.598239 33.5015 0.299119C33.3021 0.0997062 33.0029 0 32.7038 0Z"
          fill={menuOpen ? "#fff" : "#7D7E82"}
          variants={lineOneVariants}
          initial="hamburger"
          animate={menuOpen ? "cross" : "hamburger"}
        />
      </svg>
    </Wrapper>
  );
};

export default MenuIcon;
