import styled from "styled-components";
import { motion } from "framer-motion";

const Wrapper = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  overflow: auto;
  background-color: ${props => props.theme.colors.main};
  color: #fff;
`;

export default Wrapper;
