import React from "react";

//React components imports
import Navbar from "./Navbar";
import IntroText from "./IntroText";

//Styled components imports
import Wrapper from "./Wrapper";
import Container from "./Container";

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <Navbar />
        <IntroText />
      </Container>
    </Wrapper>
  );
};

export default Header;
