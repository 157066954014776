import styled from "styled-components";

const Container = styled.div`
  max-width: 1110px;
  padding: 0 20px;
  margin: 24px auto 34px;
  width: 100%;

  @media (min-width: 1150px) {
    padding: 0;
    padding-bottom: 16px;
  }
`;

export default Container;
