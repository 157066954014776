import styled from "styled-components";

const Container = styled.div`
  max-width: 1110px;
  margin: 137px auto 46px;
  margin-top: ${props => (props.home ? "0px" : "137px")};
  border-top: 1px solid #fff;
`;

export default Container;
