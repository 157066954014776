import styled from "styled-components";

const Section = styled.div`
  margin-bottom: 34px;
  @media (${props => props.theme.mediaQueries.tablet}) {
    justify-self: ${props => props.justifyEnd && "end"};
    margin-bottom: 0;
  }
`;

export default Section;
