import React from "react";
import { useLocation } from "@reach/router";

//Styled components imports
import NavLinks from "./NavLinks";
import NavLink from "./NavLink";
import Link from "./Link";
import Wrapper from "./Wrapper";

const Nav = () => {
  const location = useLocation();
  return (
    <Wrapper>
      {location.pathname === "/indukcni-varic" ||
      location.pathname === "/coin-timer" ? (
        <NavLinks>
          <NavLink>
            <Link to="/">Domů</Link>
          </NavLink>
          <NavLink>
            <Link to="/#about">O firmě</Link>
          </NavLink>
          <NavLink active>
            <Link to="/#products">Produkty</Link>
          </NavLink>
          <NavLink>
            <Link to="/#contact">Kontakt</Link>
          </NavLink>
        </NavLinks>
      ) : (
        <NavLinks>
          <NavLink active>
            <Link to="/">Domů</Link>
          </NavLink>
          <NavLink>
            <Link to="/#about">O firmě</Link>
          </NavLink>
          <NavLink>
            <Link to="/#products">Produkty</Link>
          </NavLink>
          <NavLink>
            <Link to="/#contact">Kontakt</Link>
          </NavLink>
        </NavLinks>
      )}
    </Wrapper>
  );
};

export default Nav;
