import React from "react";
import { Helmet } from "react-helmet";

import { ThemeProvider } from "styled-components";
import GlobalStyle from "../../utils/styled-components/globalStyles";
import { theme } from "../../utils/styled-components/theme";

import artemanSocial from "../../images/arteman-social.png";
import favicon from "../../images/favicon.ico";

//React components imports
import Header from "../Header";
import Main from "./Main";
import Footer from "../Footer";

//Styled components imports
import Wrapper from "./Wrapper";

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Wrapper>
        <Helmet>
          <title>Arteman</title>
          <link rel="icon" href={favicon} />
          <meta
            name="description"
            content="ArteMan s.r.o. je česká obchodní firma s technickým zaměřením. Vydavatel technologických magazínů Mobilizujeme.cz, Elektrickevozy.cz, Inteligentnisvet.cz."
          />
          <meta property="og:locale" content="cs_CZ" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Arteman" />
          <meta
            property="og:image"
            content={`https://arteman.cz${artemanSocial}`}
          />
          <meta property="og:image:width" content="630" />
          <meta property="og:image:height" content="335" />
          <meta
            property="og:description"
            content="ArteMan s.r.o. je česká obchodní firma s technickým zaměřením. Vydavatel technologických magazínů Mobilizujeme.cz, Elektrickevozy.cz, Inteligentnisvet.cz."
          />
          <meta property="og:url" content="https://arteman.cz" />
        </Helmet>
        <Header />
        <Main>{children}</Main>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  );
};

export default Layout;
