import React from "react";

//Styled components imports
import StyledTitle from "./StyledTitle";
import Wrapper from "./Wrapper";

const Title = ({ children, color }) => {
  return (
    <Wrapper>
      <StyledTitle color={color}>{children}</StyledTitle>
      <br></br>
    </Wrapper>
  );
};

export default Title;
