import React from "react";

//Styled components imports
import Wrapper from "./Wrapper";

const IntroText = () => {
  return <Wrapper>Milujeme technologie již od roku 2009</Wrapper>;
};

export default IntroText;
