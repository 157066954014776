import styled from "styled-components";

const Link = styled.a`
  color: #fff;

  &:hover {
    text-decoration: underline;
  }
`;

export default Link;
