import styled from "styled-components";
import { Link as GatsbyLink } from "gatsby";

const Link = styled(GatsbyLink)`
  color: ${props => props.theme.colors.text.lightGrey};
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 500;

  &:hover  {
    color: ${props => props.theme.colors.main};
  }
`;

export default Link;
