import styled from "styled-components";
import ArtemanLogo from "../../../../images/logo-menu.inline.svg";

const Logo = styled(ArtemanLogo)`
  cursor: pointer;
  max-width: 200px;
  margin-top: 34px;
  margin-left: 20px;
  margin-bottom: 20px;
`;

export default Logo;
