import styled from "styled-components";

const Wrapper = styled.div`
  cursor: pointer;
  display: block;
  z-index: 11;
  position: relative;
`;

export default Wrapper;
