import styled from "styled-components";

const Subtitle = styled.h4`
  font-size: 24px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 24px;
  text-align: center;

  @media (${props => props.theme.mediaQueries.tablet}) {
    text-align: left;
  }
`;

export default Subtitle;
