import styled from "styled-components";

const Main = styled.main`
  width: 100%;
  max-width: 1150px;
  padding: 0 20px;
  margin: 0 auto;
`;

export default Main;
